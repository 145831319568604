import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Book, Box, BoxArrowInRight, Diagram3, Hammer, PencilSquare, People, RocketTakeoff, Search, Star, Translate } from 'react-bootstrap-icons';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import logo2 from '../images/icon.svg';
import { useUser } from './contexts/UserContext.tsx';
import KofiPanel from './ressources/KofiPanel';
import StoryCard from './ressources/StoryCard';
import { FaqComponent } from './ressources/faqs';
import { urls } from './ressources/urls';
import { shortenDate } from './ressources/utils.js';

const counterNames = ['Writers', 'Stories', 'Parts', 'Words']

function LandingPage() {
    const { user } = useUser();
    const [system, setSystem] = useState(null);
    const [nextServerDowntime, setNextServerDowntime] = useState(null);
    const [storyOfTheWeek, setStoryOfTheWeek] = useState(null);

    const FeatureCard = ({ IconComponent, title, children }) => (
        <Card className="mb-4 feature-card">
            <Card.Body>
                <IconComponent size={30} />
                <Card.Title>{title}</Card.Title>
                <Card.Text>{children}</Card.Text>
            </Card.Body>
        </Card>
    );


    useEffect(() => {
        fetch(urls.baseUrl + urls.getLoadingPageInfo).then(response => response.json()).then(data => {
            setSystem(data.system);
            setNextServerDowntime(data.next_downtime);
        })
        fetch(urls.baseUrl + urls.getStoryOfTheWeek).then(response => response.json()).then(data => {
            setStoryOfTheWeek(data.story);
        })
    }, []);

    const ActionButton = ({ to, variant, icon: Icon, children }) => (
        <Col xs={12} lg={3} className="d-flex justify-content-center">
            <Link to={to} style={{ textDecoration: "none" }}>
                <Button
                    type="button"
                    variant={variant}
                    size="lg"
                    style={{ width: "16rem", height: "7rem", fontSize: "3rem", color: "white" }}
                    className="d-flex align-items-center justify-content-center mb-2"
                >
                    <Icon className='me-2' />{children}
                </Button>
            </Link>
        </Col>
    );

    return (
        <div className="LandingPage">
            <Container fluid>
                <Row>
                    <Col></Col>
                    <Col xs={12} lg={4}>
                        <Container>
                            <Row className="justify-content-center">
                                <Col xs={12}>
                                    <div className="d-flex justify-content-center align-items-center text-center">
                                        <div>
                                            <div className="app-logo">
                                                <div className=' app-logo-span'>
                                                    <span className='themed-text'>Cornu</span>
                                                    <span style={{ color: "#ffa600" }} >votia</span>
                                                </div>
                                                <div>
                                                    <img

                                                        src={logo2}
                                                        width="180"
                                                        height="180"
                                                        className="d-inline-block align-top svg-filter-white"
                                                        alt="Cornuvotia logo"
                                                    />
                                                </div>
                                                <div className='themed-text app-logo-sub'>Every word counts</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center text-center">
                        <Alert className='mt-3' variant='info' dismissible>
                            <Alert.Heading>Beta</Alert.Heading>
                            This is a <Link to="/changelog">beta</Link> test. There are bugs, unscheduled and scheduled server downtimes. Stories may be deleted permanently.
                            Give feedback <Link to="/feedback">here</Link> or on <Link to='https://discord.gg/grwJAzg8PH'>Discord</Link>.
                            <br />
                            <br />
                            Next scheduled server downtime: {nextServerDowntime ? shortenDate(nextServerDowntime) : 'None'}
                            <br />
                            <KofiPanel />
                            <a href="https://www.producthunt.com/posts/cornuvotia?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cornuvotia" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=470716&theme=neutral" alt="Cornuvotia - Collaborative&#0032;and&#0032;competitive&#0032;writing&#0032;platform | Product Hunt" style={{ width: "250px", height: "54px", marginLeft: "10px", marginTop: "10px" }} width="250" height="54" /></a>
                        </Alert>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    {
                        ['users', 'stories', 'parts', 'words'].map((prop, index) => (
                            <Col key={index}>
                                <div className="d-flex justify-content-center my-1">
                                    <div className="display-4" style={{ color: '#ffa600' }}>{counterNames[index]}</div>

                                </div>
                                <div className="d-flex justify-content-center my-1">
                                    <div className="display-4 fw-bold themed-text"><CountUp end={system ? system[prop] : 0} duration={5} /></div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
                <Row className='mt-5'>
                    <Col className="d-flex flex-column justify-content-around mb-5">
                        {!user && <Row className="justify-content-center align-items-center"><ActionButton to="/register" variant="primary" icon={Star}>Sign up</ActionButton></Row>}
                        <Row className="justify-content-center align-items-center"><ActionButton to="/explore" variant="explore" icon={RocketTakeoff}>Explore</ActionButton></Row>
                        <Row className="justify-content-center align-items-center"><ActionButton to="/create" variant="write" icon={Book}>Create</ActionButton></Row>
                        <Row className="justify-content-center align-items-center"><ActionButton to="/search" variant="search" icon={Search}>Search</ActionButton></Row>
                    </Col>
                    <Col >
                        <Row>
                            <Col xs={2}><span className='display-6 mt-4 themed-text' style={{ writingMode: 'vertical-lr', textOrientation: 'upright', fontSize: '2.1rem' }}>Story of the week</span></Col>
                            <Col xs={10}>
                                {storyOfTheWeek &&
                                    <div className='padding-0'><StoryCard story={storyOfTheWeek} /></div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Container className="mt-5">
                    <Row>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={Translate} title="Languages">
                                Find and create stories in your own language or use Cornuvotia to improve another.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={Book} title="Story Creation and Evolution">
                                Users can initiate a story with unique parameters like word limits and and writing time.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={PencilSquare} title="Writing Cycles and Contributions">
                                Stories go through cycles. Contributions adhere to set parameters.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={BoxArrowInRight} title="Voting and Story Progression">
                                Post writing cycle, an anonymous voting phase determines the next story segment.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={Diagram3} title="Branching Narratives">
                                Stories can branch out based on vote percentages, creating multiple story paths.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={Box} title="Post-Cycle Story Handling">
                                Completed stories are archived for future reading and participating earns achievements.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={Hammer} title="Moderation and Community Guidelines">
                                A moderation algorithm upholds community standards with user reporting for violations.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={Search} title="User Interface and Exploration Tools">
                                Users can explore random stories or use the search feature designed to find exactly the right story.
                            </FeatureCard>
                        </Col>
                        <Col md={6} lg={4}>
                            <FeatureCard IconComponent={People} title="Community Engagement">
                                Join the Discord server and leave feedback <Link to="feedback">here</Link> or on Discord.
                            </FeatureCard>
                        </Col>
                    </Row>
                </Container>
                <Row className="d-flex justify-content-center mb-3">
                    <Col xs={12} lg={5}>
                        <FaqComponent />
                    </Col>
                </Row>
            </Container>
        </div >

    );
}

export default LandingPage;

